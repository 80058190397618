.bg-dark {
  background-color: #EDB63F !important;
  ul {
    li {
      font-size: 0.8rem;
      font-weight: bold;
      color: white;
      text-transform: uppercase;
      &:hover {
        background: #d69a20;
      }
      a {
        text-decoration: none;
        color: white;
      }
    }
  }

}
.navbar-dark .navbar-nav .nav-link{
  color: white;
}
.navbar {
  margin-bottom: 1rem;
  .navbar-brand {
      height: 33px;
    img {
      height: 30px;
    }
  }
  
  .user-name{
    padding: 0px 16px;
  }
  .profile-image{
    max-height: 35px;
    border-radius: 0.5rem;
  }
  .profile-icon{
    color:white;
  }
}

.navbar-expand-lg {
  .navbar-nav {
    .dropdown-menu {
      background: #e7aa30;
      font-size: 0.8rem;
      .dropdown-item:focus,
      .dropdown-item:hover {
        background: #d69a20;
      }
    }
  }
}
