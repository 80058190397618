.profile-user-page{
    .profile-picture{
        .profile-icon{
            width: 100%;
            height: 242px;
            position: relative;
            color: #ffffff;
        }
        img{
            max-width: 100%;
            border-radius: 1rem;
        }
    }
}
