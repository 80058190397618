img.soporte{
    width: 255px;
}
.support-data{
    display: flex;
    align-items: center;
    justify-content: center;
}
.logo{
    text-align: center;;
    img{
        max-width: 200px;
    }
    margin-bottom: 2rem;
}